<template>
  <div>
    <div class="px-0 py-6 bg-white rounded-2xl shadow-lg mx-4">
      <div class="flex justify-start">
        <b class="text-left text-2xl mx-2 text-gray-600 pb-6 px-8">Información del usuario registrado</b>
      </div>
      <hr />
      <form @submit.prevent="fetchCustomer" class="
            max-w-4xl
            grid grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            justify-end
            items-end
            gap-5
            px-8
          ">
        <BaseInput v-show="disabled" ref="identificationNumber2" type="text" label="Identificación"
          placeholder="Ej: 225577885" v-model="name_identification" :title="name_identification" :required="true" :disabled="disabled" />
        <BaseSelect v-if="!disabled" ref="identificationType" label="Tipo de identificación"
          :selectOptions="formOptions.optionsIdentificationType" v-model="form.identification_type" :required="true"
          :disabled="disabled" />
        <BaseInput ref="identificationNumber" type="text" label="Número de identificación" placeholder="Ej: 225577885"
          v-model="form.identification_number" :required="true" :disabled="disabled"
          :identification="form.identification_type" />
        <a-button v-if="!disabled" type="primary" html-type="submit" class="mt-5 md:mt-px" :block="true" shape="round"
          :disabled="!(form.identification_type && form.identification_number)" :loading="loading">
          CONSULTAR USUARIO
        </a-button>
        <a-button v-if="disabled" type="primary" @click.native="cleanForm" class="mt-5 md:mt-px" :block="true"
          shape="round">
          LIMPIAR CONSULTA
        </a-button>
      </form>
    </div>
    <div v-if="user_id">
      <EditUserFinancialConsumers :userIdToGetData="user_id"> </EditUserFinancialConsumers>
    </div>
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditUserFinancialConsumers from "@/components/Dashboard/Users/EditUserFinancialConsumers.vue";

export default {
  components: {
    EditUserFinancialConsumers,
  },
  data() {
    return {
      form: {
        identification_type: "",
        identification_number: "",
      },
      legend: "",
      loading: false,
      disabled: false,
      name_identification: "",
      user_id: 0,
    };
  },
  methods: {
    fetchCustomer: async function () {
      const { identification_type, identification_number } = this.form;
      this.loading = true;
      this.customer = null;
      this.showToast("success", "Buscando datos del usuario.");
      this.user_id = null;
      this.legend = "";
      let { error, data } = await this.$api.getQueryPerson(
        `?identification_number=${identification_number}&identification_type=${identification_type}`
      );
      this.loading = false;

      if (error) {
        this.showToast(
          "error",
          "No se encontró un usuario para esa identificación."
        );
        this.legend = "No se encontró un usuario para esa identificación.";
        this.cleanForm();        
        return;
      }
      if (data) {
        this.disabled = true;
        data.results = data.results.filter(item => item.position === null)
        const identification_type_name =
          this.$store.state.form.optionsIdentificationType.find(
            (item) => item.id == identification_type
          )?.name;
        this.name_identification = identification_type_name;
        if (data.results.length === 0) {
          this.showToast(
            "error",
            "No se encontró un usuario para esa identificación."
          );
          this.legend = "No se encontró un usuario para esa identificación.";
          return;
        }
        const user = data.results[0];        
        this.user_id = user.user_id.id;
        this.legend = "";
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => { },
      });
    },
    cleanForm() {
      this.form = {
        identification_type: "",
        identification_number: "",
      };
      this.disabled = false;
    },
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  }  
};
</script>